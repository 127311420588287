


































































import Vue from 'vue';
import VTable from '@/components/table.vue';
import VMark from '@/components/mark.vue';
import { mapActions } from 'vuex';
import VForm from '@/types/vuetify';
import { CodeType } from '@/types/sqlite';

export default Vue.extend({
	name: 'CodeList',
	components: {
		VTable,
		VMark
	},
	data: (): {
		dialog: boolean;
		valid: boolean;
		editing: CodeType | null;
		codeName: string | null;
	} => ({
		dialog: false,
		valid: false,
		editing: null,
		codeName: null
	}),
	computed: {
		codes() {
			return {
				headers: [
					{ value: 'name', text: 'Name' },
					{ value: 'actions', text: 'Actions' }
				],
				data: this.$store.getters.getCodes
			};
		}
	},
	methods: {
		...mapActions([
			'addCode',
			'updateCode',
			'deleteCode',
			'createMark',
			'updateMemoCode'
		]),
		openNewCode() {
			this.editing = null;
			if (this.$refs.form) (this.$refs.form as VForm).reset();
			this.dialog = true;
		},
		openEditCode(item: CodeType) {
			this.editing = item;
			this.codeName = item.name;
			this.dialog = true;
		},
		save() {
			const isFormValidated = (this.$refs.form as VForm).validate();
			if (!isFormValidated) return;
			if (this.editing) {
				const updatedCode = { ...this.editing, name: this.codeName };
				this.updateCode(updatedCode);
			} else {
				this.addCode(this.codeName);
			}
			this.dialog = false;
		}
	}
});
