














import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { EditorContent } from 'tiptap';
import vCode from '@/views/code-list.vue';

export default Vue.extend({
	name: 'File',
	components: {
		EditorContent,
		vCode
	},
	computed: mapGetters(['getSelectedFile', 'getEditor']),
	methods: mapActions(['createEditor', 'destroyEditor']),
	beforeMount() {
		this.createEditor();
	},
	beforeDestroy() {
		this.destroyEditor();
	}
});
